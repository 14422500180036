<template>
  <section>

      <b-breadcrumb>
      <b-breadcrumb-item
        href="#"
        v-for="(item, index) in breadcrumbs"
        :key="index"
        :active="item.active"
        @click="selectedFolder(item.folder_uuid)"
        ><span v-b-tooltip.hover.bottom :title="item.text">{{ Truncate(item.text, 35 )}}</span></b-breadcrumb-item
      >
    </b-breadcrumb>    
    
    <b-table 
      :key="'inbox_' + redrawIndex"
      small
      bordered
      :items="items"
      hover
      class="ml-0 pl-0"
      :fields="fields"
      no-local-sorting
      sort-icon-left
      :sort-by.sync="tableSortBy"
      :sort-desc.sync="tableSortDesc"
      :tbody-tr-class="rowClass"
      :busy="loading"
      @row-clicked="doubleClickedRow"
      @sort-changed="sortingChanged"
    >
      <template #head(size3)>
        <b-form-checkbox
          v-if="items.length != 0"
          v-model="add_all"
          @change="addAll"
        ></b-form-checkbox>
      </template>

      <template v-slot:cell(size3)="data">
        <b-form-checkbox
          v-if="data.item.type == 'folder' && data.item.name != '..'"
          @change="checkAllSelected"
          v-model="selectedFolders"
          name="check-button"
          :value="data.item.folder_uuid"
        ></b-form-checkbox>
        <b-form-checkbox
          v-if="data.item.type == 'file' && data.item.name != '..'"
          @change="checkAllSelected"
          v-model="selectedFiles"
          name="check-button"
          :value="data.item.file_uuid"
        ></b-form-checkbox>
      </template>

      <template v-slot:cell(modified_date)="data"><span v-if="data.item.name != '..'">{{ FormatDateTime(data.item.created_date) }}</span></template>

      <template v-slot:cell(files)="data">
        <i :class="iconClass(data.item)" v-if="data.item.name != '..'"></i>
      </template>

      <template v-slot:cell(name)="data">
        <div v-if="data.item.name != '..'" v-b-tooltip.hover :title="data.item.name">{{ Truncate(data.item.name, 35 )}}</div>
        <div v-if="data.item.name == '..'"><i  v-b-tooltip.hover :title="$t('BACK')" class="fa-light fa-arrow-left"></i> {{$t('BACK')}}</div>
      </template>

      <template v-slot:cell(created_by)="data">
        <UserInfo
          v-if="data.item.created_by && data.item.type == 'file'"
          :user_uuid="data.item.created_by"
        ></UserInfo>
      </template>

      <template v-slot:cell(size2)="data">
        <b-dropdown toggle-class="no-border" no-caret target="_blank" 
        v-if="IsAdmin(data.item)">
          <template #button-content>
            <i class="fa-regular fa-ellipsis"></i>
          </template>
          <span v-if="data.item.type == 'folder'">
            <b-dropdown-item v-if="section != 'APPLICATION'"
              href="#"
              @click="folderUpdate(data.item.folder_uuid)"
              >{{ $t("UPDATE") }}</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              v-if="1 == 0"
              @click="folderMove(data.item.folder_uuid)"
              >{{ $t("FILESTORAGE.MOVE_FOLDER") }}</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              v-if="IsAdmin(data.item)"
              @click="folderUsers(data.item.folder_uuid)"
              >{{ $t("FILESTORAGE.SHARES") }}</b-dropdown-item
            >
            <b-dropdown-divider v-if="is_admin"></b-dropdown-divider>
            <b-dropdown-item
              href="#"
              variant="danger"
              v-if="is_admin"
              @click="folderRemove(data.item.folder_uuid)"
              >{{ $t("REMOVE") }}</b-dropdown-item
            >
          </span>
          <span v-if="data.item.type == 'file'">
            <b-dropdown-item  v-if="section == 'FILES'"
              href="#"
              @click="fileUpdate(data.item.file_uuid, data.item.name)"
              >{{ $t("INFORMATION") }}</b-dropdown-item
            >
            <b-dropdown-item  v-if="section == 'APPLICATION'"
            href="#"
            @click="fileInfo(data.item.file_uuid)"
            >{{ $t("INFORMATION") }}</b-dropdown-item
          >
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item
              href="#" v-if="section != 'APPLICATION'"
              @click="fileNewVersion(data.item.file_uuid)"
              >{{ $t("UPDATE") }}</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              v-if="1 == 0"
              @click="fileMove(data.item.file_uuid)"
              >{{ $t("FILESTORAGE.MOVE_FILE") }}</b-dropdown-item
            >

            <b-dropdown-item href="#" @click="fileSendAs(data.item)">{{
              $t("SEND_AS")
            }}</b-dropdown-item>

            <b-dropdown-item
              href="#"
              @click="fileDownload(data.item.file_uuid)"
              >{{ $t("DOWNLOAD") }}</b-dropdown-item
            >
            <b-dropdown-divider v-if="is_admin"></b-dropdown-divider>
            <b-dropdown-item v-if="is_admin"
              href="#"
              variant="danger"
              @click="fileRemove(data.item.file_uuid)"
              >{{ $t("REMOVE") }}</b-dropdown-item
            >
          </span>
        </b-dropdown>
        <span v-if="data.item.name != '..'"></span>
      </template>

      <template v-slot:cell(shared)="data">
        <span v-if="data.item.type == 'folder' && data.item.name != '..'">
          <span v-if="data.item.access_items">
            <span v-if="data.item.access_items.length > 1">{{
              $t("YES")
            }}</span>
            <span v-if="data.item.access_items.length == 1">{{
              $t("NO")
            }}</span>
          </span>
          <span v-if="!data.item.access_items">{{ $t("NO") }}</span>
        </span>
        <span v-if="data.item.type == 'file'">-</span>
      </template>

      <template v-slot:cell(size)="data">
        <span v-if="data.item.name != '..'">
          <span v-if="data.item.type == 'folder'">
            {{ data.item.childFolders + data.item.files }}
            {{ $t("FILESTORAGE.ITEMS") }}
          </span>
          <div v-if="data.item.type == 'file'">
            {{ bytesToSize(data.item.size) }}
          </div>
        </span>
      </template>

      <template v-slot:cell(id)="data">
        <i :class="iconClass(data.item)"></i> <span class="pl-2" v-b-tooltip.hover :title="data.item.name">{{ Truncate(data.item.name, 35 )}}</span>
        <div v-if="data.item.name != '..'">          
          <span v-if="data.item.type == 'folder'">
            {{ data.item.childFolders + data.item.files }}
            {{ $t("FILESTORAGE.ITEMS") }}
          </span>
          <span v-if="data.item.type == 'file'">
            {{ bytesToSize(data.item.size) }} 
          </span>
        </div>
        <p class="mt-1"  v-if="data.item.type == 'file'">
            {{ $t('MODIFIED_BY') }}  <UserInfo :user_uuid="data.item.created_by" name-only></UserInfo> {{ FormatDateTime(data.item.created_date) }}
          </p>

      </template>
    </b-table>

    <div v-if="showBulk">

        <b-button
          variant="danger"
          class="float-right"
          v-if="!move && is_admin"
          @click="removeSelected"
          >{{ $t("REMOVE") }}</b-button
        >
        <b-button
        variant="dark"
        :disabled="!showSendAs"
        @click="fileSendAsSelected"
        >{{ $t("SEND_AS_MESSAGE") }}</b-button
      >
      <span v-if="this.selectedFolders.length">{{ $t('CANNOT_SEND_FOLDER_IN_MESSAGE') }}</span>
  </div>

  </section>
</template>
<script>
import UserInfo from "@/components/Layout/UserInfo";
export default {
  components: { UserInfo },
  props: {
    section: {
      default: "",
      type: String,
    },
    searchText: {
      default: "",
      type: String,
    },
    move: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    is_admin: {
      default: false,
      type: Boolean,
    },
    denied_folder_uuid: {
      default: null,
      type: String,
    },
    rootName: {
      default: "",
      type: String,
    },
    folder_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      tableSortBy: '',
      tableSortDesc: false,
      currentFolderUuid: this.folder_uuid,
      add_all: false,
      selectedFolders: [],
      selectedFiles: [],
      loading: false,
      redrawIndex: 0,
      selectedIndex: 0,
      limit: 10,
      currentPage: 1,
      folders: [],
      files: [],
      crumbs: [],
      backItem: {
        id: 0,
        size: 4856,
        folder_uuid: "f1",
        name: "..",
        created_date: "2022-12-19T13:48:45Z",
        files: 4,
        folders: 2,
      },
    };
  },
  methods: {
    sortingChanged() {
      this.getFolders();
    },
    rowClass(item, type) {
      if (item && type === "row") {        
        let returnClass = "";
        if (item.listed == false) {
          returnClass += " not-viewed";
        }
        return returnClass;
      }
      return "";
    },
    removeSelected() {
      let self = this;

      let message = this.$t("FILESTORAGE.NOTE_REMOVE_SHARED");

      if(this.selectedFiles.length == 0 && this.selectedFolders.length > 1)
      {
        message = this.$t("FILESTORAGE.NOTE_REMOVE_SHARED_FOLDER_MULTIPLE");
      }
      if(this.selectedFiles.length == 0 && this.selectedFolders.length == 1)
      {
        message = this.$t("FILESTORAGE.NOTE_REMOVE_SHARED_FOLDER");
      }
      if(this.selectedFiles.length > 1 && this.selectedFolders.length == 0)
      {
        message = this.$t("FILESTORAGE.NOTE_REMOVE_SHARED_FILE_MULTIPLE");
      }
      if(this.selectedFiles.length == 1 && this.selectedFolders.length == 0)
      {
        message = this.$t("FILESTORAGE.NOTE_REMOVE_SHARED_FILE");
      }

      this.$bvModal
        .msgBoxConfirm(message, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function (value) {
          if (value) {
            self.loading = true;
            let selectedFiles = self.selectedFiles;
            let selectedFolders = self.selectedFolders;
            for (let ix = 0; ix < selectedFiles.length; ix++) {
              let RemoveFileRequestDTO = {
                user_uuid: self.user_uuid,
                file_uuid: selectedFiles[ix],
              };
              await self.$http
                .post(
                  self.user.hostname + "/filestorage/file/remove",
                  RemoveFileRequestDTO
                )
                .then(() => {
                  self.selectedFiles = self.selectedFiles.filter(
                    (item) => item !== RemoveFileRequestDTO.file_uuid
                  );
                })
                .catch(() => {
                  self.loading = false;
                });
            }
            for (let ix = 0; ix < selectedFolders.length; ix++) {
              let RemoveFolderRequestDTO = {
                user_uuid: self.user_uuid,
                folder_uuid: selectedFolders[ix],
              };
              await self.$http
                .post(
                  self.user.hostname + "/filestorage/folder/remove",
                  RemoveFolderRequestDTO
                )
                .then(() => {
                  self.selectedFolders = self.selectedFolders.filter(
                    (item) => item !== RemoveFolderRequestDTO.folder_uuid
                  );
                })
                .catch(() => {
                  self.loading = false;
                });
            }
            self.getFolders();
          }
        })
        .catch(function () {});
    },
    addAll: function () {
      if (this.add_all) {
        let filtered_files = this.items.filter(function (item) {
          return item.type == "file";
        });
        this.selectedFiles = filtered_files.map((a) => a.file_uuid);
        let filtered_folders = this.items.filter(function (item) {
          return item.type == "folder";
        });
        this.selectedFolders = filtered_folders.map((a) => a.folder_uuid);
      } else {
        this.selectedFolders = [];
        this.selectedFiles = [];
      }
    },
    folderRemove(folder_uuid) {
      let self = this;
      self.loading = true;
      let RemoveFolderRequestDTO = {
        user_uuid: this.user_uuid,
        folder_uuid: folder_uuid,
      };
      this.$bvModal
        .msgBoxConfirm(this.$t("FILESTORAGE.NOTE_REMOVE_SHARED_FOLDER"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/filestorage/folder/remove",
                RemoveFolderRequestDTO
              )
              .then(() => {
                self.loading = false;
                self.getFolders();
              })
              .catch(() => {
                self.loading = false;
              });
          }else{            
            self.loading = false;
          }
        })
        .catch(function () {});
    },
    fileRemove(file_uuid) {
      let self = this;
      self.loading = true;
      let RemoveFileRequestDTO = {
        user_uuid: this.user_uuid,
        file_uuid: file_uuid,
      };
      this.$bvModal
        .msgBoxConfirm(this.$t("FILESTORAGE.NOTE_REMOVE_SHARED_FILE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/filestorage/file/remove",
                RemoveFileRequestDTO
              )
              .then(() => {
                self.loading = false;
                self.getFolders();
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    checkAllSelected()
    {
      if((this.selectedFolders.length + this.selectedFiles.length) == this.items.length)
      {
        this.add_all = true;
      }else{
        this.add_all = false;
      }
    },
    fileInfo(file_uuid)
    {
      this.$emit("fileInfo", file_uuid);
    },
    fileUpdate(file_uuid, filename) {
      let str = filename;
      let extension = str.slice(str.lastIndexOf("."));
      let tab = 0;
      if (extension == ".txt") {
        tab = 1;
      }
      this.$emit("fileUpdate", {
        file_uuid: file_uuid,
        tab: tab,
      });
    },
    async fileDownload(file_uuid) {
      let GetFileRequestDTO = {
        user_uuid: this.user_uuid,
        file_uuid: file_uuid,
        version: null,
      };
      let FileItemDTO = await this.$http.post(
        this.user.hostname + "/filestorage/file/info",
        GetFileRequestDTO
      );
      let FileBlob = await this.$http.post(
        this.user.hostname + "/filestorage/file/get",
        GetFileRequestDTO,
        { responseType: "blob" }
      );
      this.downloadBlob(FileBlob.data, FileItemDTO.data.name);
    },
    fileSendAs(file) {
      let files = [];
      files.push(file);
      this.$emit("fileSendAs", files);
    },
    fileSendAsSelected() {
      this.$emit("fileSendAs", this.selectedFilesForSending);
    },
    folderUpdate(folder_uuid) {
      this.$emit("folderUpdate", folder_uuid);
    },
    folderUsers(folder_uuid) {
      this.$emit("folderUsers", folder_uuid);
    },
    folderMove(folder_uuid) {
      this.$emit("folderMove", folder_uuid);
    },
    fileVersions(file_uuid) {
      this.$emit("fileVersions", file_uuid);
    },
    fileNewVersion(file_uuid) {
      this.$emit("fileNewVersion", file_uuid);
    },
    fileMove(file_uuid) {
      this.$emit("fileMove", file_uuid);
    },
    refreshList(){
      this.redrawListIndex++;
    },
    async getFolders() {
      this.loading = true;
      let ListFoldersRequestDTO = {
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        user_uuid: this.user_uuid,
        parent_uuid: this.folder_uuid,
        search_text: "",
        page: 1,
        limit: 2000,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      };
      try {
        let response = await this.$http.post(
          this.user.hostname + "/filestorage/folder/list",
          ListFoldersRequestDTO
        );
        this.redrawIndex = this.redrawIndex + 1;
        this.crumbs = response.data.breadcrums;
        let folders = response.data.items.map((element) => {
          element.type = "folder";
          return element;
        });
        let denied_folder_uuid = this.denied_folder_uuid;
        this.folders = folders.filter(function (item) {
          return item.folder_uuid != denied_folder_uuid;
        });
        if(this.folder_uuid != null && this.folder_uuid != "")
        {
          if(this.breadcrumbs.length > 2)
          {
            let lastBreadCrumb = this.breadcrumbs[this.breadcrumbs.length - 2];
            this.folders.unshift({
              type: "folder",
              name: "..",
              updated: null,
              childFolders: 0,
              files: 0,
              folder_uuid: lastBreadCrumb.folder_uuid
            });
          }else{
            this.folders.unshift({
              type: "folder",
              name: "..",
              updated: null,
              childFolders: 0,
              files: 0,
              folder_uuid: ""
            });
          }
        }
        if (!this.move) {
          this.getFiles();
        } else {
          this.loading = false;
        }
      } catch {
        console.log("Could not getFolders");
      }
      this.loading = false;
    },
    async getFiles () {
      this.loading = true;
      let ListFilesRequestDTO = {
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        user_uuid: this.user_uuid,
        folder_uuid: this.folder_uuid,
        search_text: "",
        page: 1,
        limit: 2000,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      };
      try {
        let response = await this.$http.post(
          this.user.hostname + "/filestorage/file/list",
          ListFilesRequestDTO
        );
        this.$store.dispatch("user/fetchFiles");
        this.redrawIndex = this.redrawIndex + 1;
        this.$emit("changedFolder", this.folder_uuid);
        this.loading = false;
        let files = response.data.items.map((element) => {
          element.type = "file";
          return element;
        });
        this.files = files;
      } catch {
        console.log("Could not getFiles");
      }
      this.loading = false;
    },
    doubleClickedRow(data, index) {
      this.selectedIndex = index;
      if (data.type == "folder") {        
        this.selectedFolder(data.folder_uuid);
      }
      if (data.type == "file") {
        if(this.section == 'APPLICATION')
        {
          this.fileInfo(data.file_uuid);
        }else{
          this.fileUpdate(data.file_uuid, data.name);
        }
      }
    },
    selectedFolder(folder_uuid) {
      if(folder_uuid != "")
      {
        this.$router.push({ name: 'FileStorage.Files',  params: { folder_uuid: folder_uuid } }).catch(() => {});
      }else{
        this.$router.push({ name: 'FileStorage.Files' }).catch(() => {});
      }
      this.$emit("changedFolder", folder_uuid);
    },
    iconClass(item) {
      if (item.type == "folder") {
        return "fal fa-2x fa-folder";
      }
      let classStr = "fa-2x";
      let icon = this.$const.FileIcons.GetByName(item.name);
      return icon + " " + classStr;
    },
    IsAdmin(item)
    {
      if(item.type == "folder")
      {
        if(item.access_items != undefined)
        {
          let self = this;
          return item.access_items.filter(function (item) {
            if(self.user_uuid != "")
            {
              return (self.user_uuid == item.user_uuid && item.is_admin == 1);
            }else{
              return (self.user.information.user_uuid == item.user_uuid && item.is_admin == 1);
            }}).length != 0;
        }else{
          return false;
        }
      }else{
        return this.is_admin;
      }
    }
  },
  computed: {
    sortBy(){
      switch(this.tableSortBy)
      {
        case "modified_date": return "UPDATED";
        case "size": return "SIZE";
      }
      return this.section == "APPLICATION" ? "UPDATED" : "NAME";
    },
    sortDesc(){
      return this.tableSortDesc;
    },
    showSendAs()
    {
      return this.selectedFolders.length == 0 && !this.move;
    },
    selectedFilesForSending(){
      let self = this;
      return this.files.filter(function (item) {
        return self.selectedFiles.includes(item.file_uuid);
      });
    },
    showBulk() {
      return this.selectedFolders.length != 0 || this.selectedFiles.length != 0;
    },
    showTable() {
      if (this.items) {
        if (this.move && this.items.length == 0) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    totalItems() {
      if (this.items) {
        return this.items.length;
      } else {
        return 0;
      }
    },
    breadcrumbs() {
      let currentName = this.$t("FILESTORAGE.FILES");
      let items = [];
      for (let ix = 0; ix < this.crumbs.length; ix++) {
        let crumb = this.crumbs[ix];
        if (crumb.name == "root") {
          items.push({
            folder_uuid: "",
            text: currentName,
            active: this.crumbs.length - 1 == ix,
          });
        } else {
          items.push({
            folder_uuid: crumb.value,
            text: crumb.name,
            active: this.crumbs.length - 1 == ix,
          });
        }
      }
      this.$emit("setCrumbs", items);
      return items;
    },
    items() {
      let items = [];
      items = items.concat(this.folders);
      items = items.concat(this.files);
      return items;
    },
    fields() {
      if (this.move) {
        return [
          {
            key: "name",
            label: this.$t("NAME"),
            class: "text-break w-300 clickable",
            thClass: "hidden",
            sortable: true,
          },
        ];
      } else {
        return [
          {
            key: "size3",
            label: "",
            class: "text-break w-30 clickable",
            sortable: false,
          },
          {
            key: "id",
            label: "",
            class: "hidden-not-mobile",
            thClass: "hidden-not-mobile",
          },
          {
            key: "files",
            label: "",
            class: "text-break w-30 clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "name",
            label: this.$t("NAME"),
            class: "text-break w-300 clickable hidden-mobile",
            sortable: true,
          },
          {
            key: "size",
            label: this.$t("SIZE"),
            class: "text-break w-150 clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: true,
          },
          {
            key: "modified_date",
            label: this.$t("MODIFIED"),
            class: "text-break w-date clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: true,
          },
          {
            key: "created_by",
            label: this.$t("MODIFIED_BY"),
            class: "text-break w-date clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "shared",
            label: this.$t("SHARED"),
            class: "text-break w-150 clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "size2",
            label: "",
            class: "text-break w-icon clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
        ];
      }
    },
  },
  watch: {
    searchText: function () {
      if (this.searchText.length == 0) {
        this.getFolders();
      }
      if (this.searchText.length >= 2) {
        this.getFolders();
      }
    },
    folder_uuid: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("changedFolder", newVal);
        this.getFolders();
      }
    },
  },
  mounted: function () {
    this.$emit('changedFolder',this.folder_uuid);
    this.getFolders();
    if(this.section == "APPLICATION"){
      this.tableSortDesc = true;
    }
    this.$emit('changeSection', this.section);
  },
};
</script>
<style>
</style>
